<template>
  <div class="cost">
    <Header back="返回" title="研发项目" index="首页" titleOne="科创模块" titleTwo="研发项目" beforeTitle="新增/编辑" />
    <div class="content">
      <h1>项目信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="项目名称" prop="rd_name">
          <el-input v-model="form.rd_name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="RD编号">
          <el-input v-model="form.rd_number" placeholder="请输入RD编号"></el-input>
        </el-form-item>
        <el-form-item label="成果转化形式">
          <el-select v-model="form.convert_type_id" placeholder="请选择成果转化形式">
            <el-option
              v-for="item in convert_type_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="研发类型" prop="rd_type">
          <el-select v-model="form.rd_type" placeholder="请选择研发类型">
            <el-option label="自主研发" :value="1">自主研发</el-option>
            <el-option label="联合开发" :value="2">联合开发</el-option>
            <el-option label="委外研发" :value="3">委外研发</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="研发人数(人)">
          <el-input v-model="form.rd_personnel" placeholder="请输入研发人数"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="负责人">
          <el-input v-model="form.charge_person" placeholder="请输入负责人"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="总预算(万元)">
          <el-input v-model="totalBudget" placeholder="请选择成果转化形式" disabled></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="第一年年份">
          <el-input v-model="form.first_year" placeholder="请输入年份"></el-input>
        </el-form-item>
        <el-form-item label="第一年预算(万元)">
          <el-input v-model="form.first_budget" placeholder="请输入第一年预算"></el-input>
        </el-form-item>
        <el-form-item label="第二年年份">
          <el-input v-model="form.second_year" placeholder="请输入年份"></el-input>
        </el-form-item>
        <el-form-item label="第二年预算(万元)">
          <el-input v-model="form.second_budget" placeholder="请输入第二年预算"></el-input>
        </el-form-item>
        <el-form-item label="第三年年份">
          <el-input v-model="form.third_year" placeholder="请输入年份"></el-input>
        </el-form-item>
        <el-form-item label="第三年预算(万元)">
          <el-input v-model="form.third_budget" placeholder="请输入第三年预算"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <!-- <el-form-item label="绑定PS">
					<el-autocomplete class="inline-input" v-model="form.ps_name" :fetch-suggestions="fuzzySearch" @select="fuzzySelect"
					  placeholder="关键字筛选"></el-autocomplete>
        </el-form-item>-->
      </el-form>
      <div class="clear"></div>

      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        rd_name: "",
        company_name: "",
        rd_number: "",
        convert_type_id: "",
        start_time: "",
        end_time: "",
        rd_type: "",
        rd_personnel: "",
        charge_person: "",
        total_budget: "",
        first_year: "",
        first_budget: "",
        second_year: "",
        second_budget: "",
        third_year: "",
        third_budget: "",
        // ps_name:'',
        // ps_id:'',
      },
      convert_type_id: [],
      formRule: {
        rd_name: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入企业名称关键字",
            trigger: "blur",
          },
        ],
        start_time: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        end_time: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        rd_type: [
          {
            required: true,
            message: "请选择研发类型",
            trigger: "change",
          },
        ],
      },
    };
  },
  created () {
    this.getConvert_type_id();
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
    totalBudget: function () {
      let sum = 0;
      let sum1 = parseFloat(this.form.first_budget) || 0;
      let sum2 = parseFloat(this.form.second_budget) || 0;
      let sum3 = parseFloat(this.form.third_budget) || 0;
      sum = sum1 + sum2 + sum3;
      console.log(111);
      return sum || 0;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/rd/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    getConvert_type_id () {
      this.axios.get("/api/type/list").then((res) => {
        this.convert_type_id = res.data;
      });
    },
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    // fuzzySearch(queryString, cb) {
    //   clearTimeout(this.timeout);
    //   var results = [];
    //   if (queryString == "") {
    //     cb(results);
    //   } else {
    //     this.axios
    //       .get("/api/ps/select_ps_list", {
    //         params: {
    //           keywords: queryString,
    //         },
    //       })
    //       .then((res) => {
    //         console.log(res);
    //         if (res.message == "查询成功") {
    //           for (let i = 0; i < res.data.length; i++) {
    //             const element = res.data[i];
    //             console.log(element);
    //             results.push({
    //               value: element.ps_name,
    //               id: element.id,
    //             });
    //             console.log(results);
    //           }
    //           cb(results);
    //         } else {
    //           results = [];
    //           cb(results);
    //         }
    //       });
    //   }
    // },
    //点击出现搜索后点击的每一项
    // fuzzySelect(item) {
    // 	console.log(item)
    // 	this.form.ps_name = item.value
    // 	this.form.ps_id = item.id
    // },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.com_id = this.form.com_id;
        this.form.total_budget = this.totalBudget;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/rd/store", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/rd/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请输入必填项");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}
.el-select {
  width: 100%;
}
.ImportTop span {
  color: #a6abc7;
}
</style>
